import Loader from 'components/Loader';
import { DASHBOARD_TYPES } from 'constants/global';
import ROUTES from 'constants/routes';
import useUser from 'hooks/useUser';
import DashboardLayout from 'layouts/DashboardLayout';
import OnboardLayout from 'layouts/OnboardLayout';
import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getFromStorage } from 'utils';

const Login = lazy(() => import('pages/Auth/Login'));
const Register = lazy(() => import('pages/Auth/Register'));
const RecoverPassword = lazy(() => import('pages/Auth/RecoverPassword'));
const WholesaleDashboard = lazy(() => import('pages/Dashboard/WholesaleDashboard'));
const LogisticsDashboard = lazy(() => import('pages/Dashboard/LogisticsDashboard'));
const Trades = lazy(() => import('pages/Wholesale/Trades'));
const Requisitions = lazy(() => import('pages/Wholesale/Requisitions'));
const Sales = lazy(() => import('pages/Wholesale/Sales'));
const Farmers = lazy(() => import('pages/Wholesale/Farmers'));
const Profile = lazy(() => import('pages/Profile'));
const FinanceRequests = lazy(() => import('pages/Wholesale/Finance/Requests'));
const Storage = lazy(() => import('pages/Wholesale/Storage'));
const Inspection = lazy(() => import('pages/Wholesale/Inspection'));
const OnlineSales = lazy(() => import('pages/Wholesale/OnlineSales'));
const ContributionMargin = lazy(() => import('pages/Wholesale/Metrics/ContributionMargin'));
const LocationsCM2 = lazy(() => import('pages/Wholesale/Metrics/LocationsCM2'));
const Inventory = lazy(() => import('pages/Wholesale/Metrics/Inventory'));
const SupplyTrades = lazy(() => import('pages/Logistics/SupplyTrades'));
const CompanyAssets = lazy(() => import('pages/Logistics/CompanyAssets'));
const Deliveries = lazy(() => import('pages/Logistics/Deliveries'));
const Expenses = lazy(() => import('pages/Logistics/Expenses'));
const Maintenance = lazy(() => import('pages/Logistics/Maintenance'));

const App = () => {
    const { hasAllAccess, isWholeSale, isTrade, isSuperAdmin, user, isWholeSaleManager } = useUser();
    const dashboardType = getFromStorage('ams-dashboard-type');

    return (
        <Suspense
            fallback={
                <div className="h-screen flex items-center justify-center">
                    <Loader />
                </div>
            }
        >
            <Routes>
                <Route element={<OnboardLayout />}>
                    <Route path={ROUTES.LOGIN} element={<Login />} />
                    <Route path={ROUTES.REGISTER} element={<Register />} />
                    <Route path={ROUTES.RECOVER} element={<RecoverPassword />} />
                </Route>

                <Route element={<DashboardLayout />}>
                    <Route
                        path={ROUTES.HOMEPAGE}
                        element={
                            dashboardType === DASHBOARD_TYPES.WHOLESALE ? (
                                <WholesaleDashboard />
                            ) : (
                                <LogisticsDashboard />
                            )
                        }
                    />
                    {dashboardType === DASHBOARD_TYPES.WHOLESALE ? (
                        <>
                            {user?.role && (
                                <>
                                    {(isTrade || hasAllAccess || isWholeSaleManager) && (
                                        <Route path={ROUTES.TRADES} element={<Trades />} />
                                    )}
                                    {(isWholeSale || hasAllAccess || isWholeSaleManager) && (
                                        <Route path={ROUTES.SALES} element={<Sales />} />
                                    )}
                                    <Route path={ROUTES.REQUISITIONS} element={<Requisitions />} />
                                    {(isWholeSale || hasAllAccess || isWholeSaleManager) && (
                                        <Route path={ROUTES.FARMERS} element={<Farmers />} />
                                    )}
                                    {isSuperAdmin && <Route path={ROUTES.STORAGE} element={<Storage />} />}
                                    {(hasAllAccess || isWholeSaleManager) && (
                                        <>
                                            <Route path={ROUTES.INSPECTIONS} element={<Inspection />} />
                                            <Route path={ROUTES.ONLINE} element={<OnlineSales />} />
                                            <Route path={ROUTES.CONTRIBUTION_MARGIN} element={<ContributionMargin />} />
                                            <Route path={ROUTES.LOCATIONS_CM2} element={<LocationsCM2 />} />
                                            <Route path={ROUTES.INVENTORY} element={<Inventory />} />
                                        </>
                                    )}
                                    {hasAllAccess && (
                                        <Route path={ROUTES.FINANCE_REQUISITIONS} element={<FinanceRequests />} />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Route path={ROUTES.SUPPLY_TRADES} element={<SupplyTrades />} />
                            <Route path={ROUTES.ASSETS} element={<CompanyAssets />} />
                            <Route path={ROUTES.DELIVERIES} element={<Deliveries />} />
                            <Route path={ROUTES.EXPENSES} element={<Expenses />} />
                            <Route path={ROUTES.MAINTENANCE} element={<Maintenance />} />
                        </>
                    )}
                    <Route path={ROUTES.PROFILE} element={<Profile />} />
                </Route>

                <Route path={'*'} element={<Navigate to={ROUTES.HOMEPAGE} />} />
            </Routes>
        </Suspense>
    );
};

export default App;
