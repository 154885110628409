const ROUTES = Object.freeze({
    LOGIN: '/login',
    REGISTER: '/register',
    RECOVER: '/recover-password',

    HOMEPAGE: '/',
    TRADES: '/trades',
    SALES: '/sales',
    REQUISITIONS: '/requisitions',

    FINANCE_REQUISITIONS: '/finance/requisitions',

    FARMERS: '/farmers',
    STORAGE: '/storage',
    INSPECTIONS: '/inspections',
    ONLINE: '/online-sales',
    CONTRIBUTION_MARGIN: '/contribution-margin',
    LOCATIONS_CM2: '/locations-cm2',
    INVENTORY: '/inventory',

    PROFILE: '/profile',

    //
    // LOGISTICS
    //

    SUPPLY_TRADES: '/supply-trades',
    ASSETS: '/assets',
    DELIVERIES: '/deliveries',
    EXPENSES: '/expenses',
    MAINTENANCE: '/maintenance',
});

export default ROUTES;
