import { Link } from 'react-router-dom';
import amsLogo from 'assets/images/agrologo.jpg';
import amsFavIcon from 'assets/images/agro-favicon.png';
import Avatar from 'components/Avatar';
import { useState } from 'react';
import ROUTES from 'constants/routes';
import useUser from 'hooks/useUser';
import capitalizeWords from 'utils/capitalizeWords';
import { signOut } from 'firebase/auth';
import { auth, db, fetchCollectionToRedux, getFromStorage, modifyAuthError, saveToStorage, setUser } from 'utils';
import { toast } from 'react-toastify';
import Table from 'components/Tables/containers/Table';
import TableItem from 'components/Tables/containers/TableItem';
import NotFound from 'components/NotFound';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Modal';
import { useEffect } from 'react';
import { setUsers } from 'redux/slices/app';
import Loader from 'components/Loader';
import Dropdown from 'components/Dropdown';
import { DASHBOARD_TYPES, DEPARTMENTS } from 'constants/global';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { formatDateValues } from 'utils/formatDateValues';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';

export const Nav = ({ sideBarOpen, setSideBarOpen }) => {
    const { user, isSuperAdmin, isAccountant } = useUser();
    const [openProfile, setOpenProfile] = useState(false);
    const [openRoleModal, setOpenRoleModal] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const dashboardTypeToSet =
        getFromStorage('ams-dashboard-type') === DASHBOARD_TYPES.WHOLESALE
            ? DASHBOARD_TYPES.LOGISTICS
            : DASHBOARD_TYPES.WHOLESALE;

    const logout = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            toast.error(modifyAuthError(error?.code));
        }
    };

    return (
        <>
            <nav
                className={`bg-white border-b h-[58px] grid transition-all duration-300 ${
                    sideBarOpen ? 'grid-cols-[230px_1fr]' : 'grid-cols-[75px_1fr]'
                }`}
            >
                <div
                    className={`border-r h-full flex items-center relative bg-white ${
                        sideBarOpen
                            ? 'max-sm:fixed max-sm:!h-[58px] max-sm:border-b !left-0 !top-0 max-475:w-[200px] max-sm:w-[230px] max-sm:!z-[1200] px-5'
                            : 'justify-center pr-2'
                    }`}
                >
                    <Link to={'/'}>
                        {sideBarOpen ? (
                            <img src={amsLogo} className="w-28 max-sm:h-[35px]" alt="Agro Market AMS" />
                        ) : (
                            <img src={amsFavIcon} className="w-11 max-sm:h-11" alt="Agro Market AMS" />
                        )}
                    </Link>

                    <span
                        className={`material-symbols-outlined text-base max-sm:border border-white bg-primary/90 py-[1px] px-[5px] cursor-pointer text-white font-normal rounded-full absolute -right-3 top-[19px] ${
                            sideBarOpen && 'rotate-180'
                        }`}
                        onClick={() => {
                            setSideBarOpen((prev) => !prev);
                        }}
                    >
                        keyboard_double_arrow_right
                    </span>
                </div>

                <div className={`${sideBarOpen && 'max-sm:col-span-2'} flex items-center pr-3 375:pr-6`}>
                    <div
                        className="flex cursor-pointer relative z-50 ml-auto"
                        onClick={() => setOpenProfile((prev) => !prev)}
                    >
                        <Avatar
                            fullName={user?.fullName}
                            img={user?.photoURL}
                            className={'w-[42px] h-[42px] cursor-pointer !rounded-[5px] !mr-0 375:!mr-3.5'}
                        />

                        <div className="max-375:hidden">
                            <p className={`flex font-semibold text-[15px] ${!user?.role && 'translate-y-2.5'}`}>
                                {capitalizeWords(user?.fullName || '')}
                                <span
                                    className={`material-symbols-outlined text-[20px] font-bold ml-[5px] transition-transform duration-300 ${
                                        openProfile ? 'rotate-0' : 'rotate-180 relative bottom-0.5'
                                    }`}
                                >
                                    expand_less
                                </span>
                            </p>
                            <p className={`text-[12.4px] text-text-light font-semibold mt-[-2px] capitalize`}>
                                {user?.role}
                            </p>
                        </div>

                        {/* Profile Popup */}
                        <div
                            className={`rounded bg-white shadow-dropdownShadow w-max max-w-[190px] absolute z-50 right-0 top-[122%] transition-all duration-300 ease-in ${
                                openProfile ? 'translate-y-0 opacity-100' : 'invisible translate-y-[70px] opacity-0'
                            }`}
                        >
                            <Link
                                to={user?.role ? ROUTES.PROFILE : '#'}
                                className="375:hidden not-hover px-3.5 py-2 flex"
                            >
                                <span className="material-symbols-outlined text-lg mr-2 text-text-light/70">
                                    account_box
                                </span>
                                <div>
                                    <p className={`font-semibold text-[15px] ${!user?.role && 'translate-y-[3px]'}`}>
                                        {capitalizeWords(user?.fullName || '')}
                                    </p>
                                    <p className={`text-[12.4px] text-text-light font-semibold mt-[-2px]`}>
                                        {user?.role}
                                    </p>
                                </div>
                            </Link>

                            {user?.role && (
                                <Link
                                    to={ROUTES.PROFILE}
                                    className="max-375:hidden not-hover flex items-center w-full py-1.5 text-sm text-text-light font-medium px-3.5 transition-colors hover:bg-gray-100"
                                >
                                    <span className="material-symbols-outlined text-lg mr-2 text-text-light/70">
                                        account_box
                                    </span>
                                    My Profile
                                </Link>
                            )}

                            {isSuperAdmin && (
                                <>
                                    <p
                                        className="flex items-center w-full py-1.5 text-sm text-text-light font-medium px-3.5 transition-colors hover:bg-gray-100"
                                        onClick={() => setOpenRoleModal(true)}
                                    >
                                        <span className="material-symbols-outlined text-lg mr-2 text-text-light/70">
                                            group_add
                                        </span>
                                        Assign Roles
                                    </p>
                                </>
                            )}
                            {(isSuperAdmin || isAccountant) && (
                                <p
                                    className="flex items-center w-full py-1.5 text-sm text-text-light font-medium px-3.5 transition-colors hover:bg-gray-100"
                                    onClick={() => {
                                        saveToStorage('ams-dashboard-type', dashboardTypeToSet);
                                        window.dispatchEvent(new Event('storage'));
                                    }}
                                >
                                    <span className="material-symbols-outlined text-[19px] translate-x-[-1px] mr-[6px] text-text-light/70">
                                        change_circle
                                    </span>
                                    Switch To {capitalizeFirstLetter(dashboardTypeToSet)}
                                </p>
                            )}

                            <p
                                className="flex items-center w-full py-1.5 text-sm text-ams-red font-medium border-t px-3.5 transition-colors hover:bg-gray-100"
                                onClick={logout}
                            >
                                <span className="material-symbols-outlined text-lg mr-2">logout</span> Logout
                            </p>
                        </div>
                    </div>
                </div>

                {(openProfile || openNotification) && (
                    <div
                        className="fixed w-screen h-screen left-0 top-0 z-40 cursor-default"
                        onClick={() => {
                            setOpenNotification(false);
                            setOpenProfile(false);
                        }}
                    />
                )}
            </nav>

            {openRoleModal && (
                <Modal showCloseIcon={false}>
                    <AssignRoles closeModal={() => setOpenRoleModal(false)} />
                </Modal>
            )}
        </>
    );
};

const AssignRoles = ({ closeModal }) => {
    const [usersLoading, setUsersLoading] = useState(true);
    const { users: storeUsers } = useSelector((state) => state.app);
    const users = storeUsers?.filter((user) => user.role !== 'super admin');

    useEffect(() => {
        fetchCollectionToRedux('users', setUsers, setUsersLoading);
    }, []);

    return (
        <div
            className={`rounded-lg w-[92vw] sm:w-[550px] bg-white relative ${
                !users?.length || usersLoading ? 'grid place-items-center h-[87vh]' : 'pt-7 pb-4'
            }`}
        >
            <span
                className="material-symbols-outlined text-text-light cursor-pointer font-semibold absolute top-2 right-2"
                onClick={closeModal}
            >
                close
            </span>

            {usersLoading ? (
                <Loader />
            ) : (
                <>
                    {users?.length ? (
                        <Table
                            tableClassName={'!w-full'}
                            className={'grid-cols-[265px_170px_70px] px-0.5 sm:px-2.5'}
                            titles={[{ title: 'User Full Name' }, { title: 'Current Role' }, { title: 'Action' }]}
                        >
                            <div className="h-[70vh] max-sm:overflow-y-auto">
                                {users.map((user, idx) => (
                                    <User
                                        idx={idx}
                                        users={users}
                                        key={user.id}
                                        user={user}
                                        moreThanOne={users.length > 1}
                                    />
                                ))}
                            </div>
                        </Table>
                    ) : (
                        <NotFound className={'-translate-y-9'} message="No Users Found." />
                    )}
                </>
            )}
        </div>
    );
};

const User = ({ user, moreThanOne, users, idx }) => {
    const [updateLoading, setUpdateLoading] = useState(false);
    const dispatch = useDispatch();

    const updateRole = async (role) => {
        try {
            setUpdateLoading(true);

            const userRef = doc(db, 'users', user.id);
            await updateDoc(userRef, { role, updatedAt: serverTimestamp() });

            const userSnapshot = await getDoc(userRef);
            const _user = {
                ...userSnapshot.data(),
                ...formatDateValues(userSnapshot.data()),
            };

            users[idx] = _user;
            dispatch(setUsers(users));
        } catch (error) {
            toast.error(`Request failed. Please update ${capitalizeWords(user.fullName)} role again.`);
        } finally {
            setUpdateLoading(false);
        }
    };

    return (
        <TableItem className={'grid-cols-[265px_170px_70px] px-0.5 sm:px-2.5'} key={user.id} moreThanOne={moreThanOne}>
            <td className="flex items-center capitalize">
                <Avatar img={user?.photoURL} fullName={user.fullName} />
                {user.fullName}
            </td>
            <td className="capitalize">{user?.role ?? 'N/A'}</td>
            <td>
                {updateLoading ? (
                    <Loader />
                ) : (
                    <Dropdown
                        dropdown={<span className="material-symbols-outlined">manage_accounts</span>}
                        dropdownContainerClasses={'!w-[188px] mt-1 !border-0 shadow-dropdownShadow rounded -right-3'}
                        name={'UpdateRole'}
                        handleChange={(_, value) => updateRole(value.toLowerCase())}
                        dropdownOptions={[...DEPARTMENTS, user?.role ? 'Remove Access' : '']
                            .filter((dept) => dept && dept.toLowerCase() !== user?.role && dept !== 'Super Admin')
                            .map((dept) => ({
                                label: dept.startsWith('Remove') ? (
                                    <span
                                        className="text-red-500"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            updateRole(null);
                                        }}
                                    >
                                        {dept}
                                    </span>
                                ) : (
                                    dept
                                ),
                                value: dept,
                            }))}
                    />
                )}
            </td>
        </TableItem>
    );
};
