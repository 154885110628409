import { useEffect, useState } from 'react';
import { getUser } from 'utils';

const useUser = () => {
    const [user, setUser] = useState(getUser());

    const handleUser = () => {
        setUser(getUser());
    };

    const isSuperAdmin = user?.role === 'super admin';
    const isExport = user?.role === 'export';
    const isHR = user?.role === 'Human Resource';
    const isFinance = user?.role === 'finance';
    const isCS = user?.role === 'customer service';
    const isWholeSale = user?.role === 'wholesale';
    const isTrade = user?.role === 'trade';
    const isLogistics = user?.role === 'logistics';
    const isWholeSaleManager = user?.role === 'wholesale manager';
    const isAccountant = user?.role === 'accountant';

    const hasAllAccess = isFinance || isSuperAdmin || isAccountant;

    useEffect(() => {
        window.addEventListener('storage', handleUser);
        return () => {
            window.removeEventListener('storage', handleUser);
        };
    }, []);

    return {
        user,
        isFinance,
        isSuperAdmin,
        isExport,
        isHR,
        isCS,
        isWholeSale,
        isTrade,
        isLogistics,
        isWholeSaleManager,
        hasAllAccess,
        isAccountant,
    };
};

export default useUser;
