export const DEPARTMENTS = [
    'Logistics',
    'Trade',
    'Customer Service',
    'Export',
    'Wholesale',
    'Finance',
    'Accountant',
    'Human Resource',
    'Wholesale Manager',
    'Super Admin',
];

export const REQUEST_TYPES = [
    'Logistics',
    'Work Tools',
    'Packaging',
    'Trade Funding',
    'Salaries',
    'Airtime / Data',
    'Security',
    'Utilities',
    'Internet',
    'Condolence',
    'Bonus',
    'Salary Advance',
    'Misc',
];

export const LOGISTICS_EXPENSE_TYPES = [
  'Fuel',
  'Repairs',
  'Maintenance',
  'Documentation',
  'Penalty',
  'Salary',
] 

export const ITEMS_PER_PAGE = 20;

export const DASHBOARD_TYPES = {WHOLESALE:'retail', LOGISTICS:'logistics'}

export const NIGERIAN_STATES = [
    'Abia',
    'Adamawa',
    'Akwa Ibom',
    'Anambra',
    'Bauchi',
    'Bayelsa',
    'Benue',
    'Borno',
    'Cross River',
    'Delta',
    'Ebonyi',
    'Edo',
    'Ekiti',
    'Enugu',
    'Gombe',
    'Imo',
    'Jigawa',
    'Kaduna',
    'Kano',
    'Katsina',
    'Kebbi',
    'Kogi',
    'Kwara',
    'Lagos',
    'Nasarawa',
    'Niger',
    'Ogun',
    'Ondo',
    'Osun',
    'Oyo',
    'Plateau',
    'Rivers',
    'Sokoto',
    'Taraba',
    'Yobe',
    'Zamfara',
    'Abuja',
];

export const PRODUCTS = {
  Grains: [
    {
      productname: "Beans - Oloyin Maiduguri",
      productunit: "",
    },
    {
      productname: "Beans - Oloyin Niger",
      productunit: "",
    },
    {
      productname: "Beans - Oloyin Nasarawa",
      productunit: "",
    },
    {
      productname: "Beans - Oloyin Pelebe",
      productunit: "",
    },
    {
      productname: "Soy Beans",
      productunit: "",
    },
    {
      productname: "Beans - Olotu",
      productunit: "",
    },
    {
      productname: "Beans - Potasco",
      productunit: "",
    },
    {
      productname: "Beans - Iron or White",
      productunit: "",
    },
    {
      productname: "Beans - Kampala",
      productunit: "",
    },
    {
      productname: "Millet",
      productunit: "",
    },
    {
      productname: "Corn - Yellow",
      productunit: "",
    },
    {
      productname: "Corn - White",
      productunit: "",
    },
    {
      productname: "Corn - Red",
      productunit: "",
    },
    {
      productname: "Guinea Corn - White",
      productunit: "",
    },
    {
      productname: "Guinea Corn - Red",
      productunit: "",
    },
    {
      productname: "Garri - Yellow",
      productunit: "",
    },
    {
      productname: "Garri - White",
      productunit: "",
    },
    {
      productname: "Garri Bendel - White",
      productunit: "",
    },
    {
      productname: "Garri - Ijebu",
      productunit: "",
    },
    {
      productname: "Garri - Ijebu Premium",
      productunit: "",
    },
    {
      productname: "Groundnut",
      productunit: "",
    },
    {
      productname: "Tuwo Rice",
      productunit: "",
    },
    {
      productname: "Ofada Rice - Local",
      productunit: "",
    },
    {
      productname: "Ebonyi Rice",
      productunit: "",
    },
    {
      productname: "Rice",
      productunit: "",
    },
    {
      productname: "Oats",
      productunit: "",
    },
  ],
  Tuber: [
    {
      productname: "Potato - Irish",
      productunit: "",
    },
    {
      productname: "Sweet Potato Flour",
      productunit: "",
    },
    {
      productname: "Potato - Irish ",
      productunit: "",
    },
    {
      productname: "Potato - Sweet (Purple)",
      productunit: "",
    },

    {
      productname: "Potato - Sweet (White)",
      productunit: "",
    },
    {
      productname: "Yam - New Mumuyi (Small)",
      productunit: "",
    },
    {
      productname: "Yam - New Mumuyi (Medium)",
      productunit: "",
    },
    {
      productname: "Yam - New Mumuyi (Large)",
      productunit: "",
    },
    {
      productname: "Yam - New Onitsha (Small)",
      productunit: "",
    },

    {
      productname: "Yam - New Onitsha (Medium)",
      productunit: "",
    },

    {
      productname: "Yam - New Onitsha (Large)",
      productunit: "",
    },

    {
      productname: "Yam - Benue (Small)",
      productunit: "",
    },
    {
      productname: "Yam - Benue (Medium)",
      productunit: "",
    },
    {
      productname: "Yam - Benue (Large)",
      productunit: "",
    },
    {
      productname: "Yam - Ogoja (Small)",
      productunit: "",
    },
    {
      productname: "Yam - Ogoja (Medium)",
      productunit: "",
    },
    {
      productname: "Yam - Ogoja (Large)",
      productunit: "",
    },
    {
      productname: "Yam - Nassarawa (Small)",
      productunit: "",
    },
    {
      productname: "Yam - Nassarawa (Medium)",
      productunit: "",
    },
    {
      productname: "Yam - Nassarawa (Large)",
      productunit: "",
    },
    {
      productname: "Yam - Abuja (Small)",
      productunit: "",
    },
    {
      productname: "Yam - Abuja (Medium)",
      productunit: "",
    },
    {
      productname: "Yam - Abuja (Large)",
      productunit: "",
    },
    {
      productname: "Yam - Bendel (Small)",
      productunit: "",
    },
    {
      productname: "Yam - Bendel (Medium)",
      productunit: "",
    },
    {
      productname: "Yam - Bendel (Large)",
      productunit: "",
    },
    {
      productname: "Water Yam",
      productunit: "",
    },
    {
      productname: "Dried Yam",
      productunit: "",
    },
    {
      productname: "Yam Flour (Elubo Isu)",
      productunit: "",
    },
    {
      productname: "Cocoyam ",
      productunit: "",
    },
    {
      productname: "Cocoyam - Soup Thickener",
      productunit: "",
    },
    {
      productname: "Cassava",
      productunit: "",
    },
    {
      productname: "Cassava Flour",
      productunit: "",
    },

    {
      productname: "Lafun - Dried Cassava",
      productunit: "",
    },

    {
      productname: "Dry Split Ginger",
      productunit: "",
    },

    {
      productname: "Tigernut",
      productunit: "",
    },
  ],
  Vegetable: [
    {
      productname: "Ogbono Ijaw",
      productunit: "",
    },
    {
      productname: "Ogbono Cotonou",
      productunit: "",
    },
    {
      productname: "Ogbono Ado",
      productunit: "",
    },
    {
      productname: "Ogbono Ujiri",
      productunit: "",
    },
    {
      productname: "Okro",
      productunit: "",
    },
    {
      productname: "Tomato Jos - Grade A",
      productunit: "",
    },
    {
      productname: "Tomato Jos - Grade B",
      productunit: "",
    },
    {
      productname: "Tomato Jos - Grade C",
      productunit: "",
    },
    {
      productname: "Tomato Cameroon",
      productunit: "",
    },
    {
      productname: "Tomato Yoruba / Kerewa",
      productunit: "",
    },
    {
      productname: "Tomato Benue - Grade A",
      productunit: "",
    },
    {
      productname: "Tomato Benue - Grade B",
      productunit: "",
    },
    {
      productname: "Cherry Tomatoes",
      productunit: "",
    },
    {
      productname: "Onions - Red",
      productunit: "",
    },
    {
      productname: "Onions - Light Red",
      productunit: "",
    },
    {
      productname: "Onions - White",
      productunit: "",
    },
    {
      productname: "Spring Onion",
      productunit: "",
    },
    {
      productname: "Garlic",
      productunit: "",
    },
    {
      productname: "Pepper - Ata Rodo Grade A",
      productunit: "",
    },
    {
      productname: "Pepper - Tatase",
      productunit: "",
    },
    {
      productname: "Pepper - Tatase (Dry)",
      productunit: "",
    },
    {
      productname: "Pepper - Yellow Cameroon ",
      productunit: "",
    },
    {
      productname: "Pepper - Shombo",
      productunit: "",
    },
    {
      productname: "Pepper - Green Bell",
      productunit: "",
    },
    {
      productname: "Pepper - Red Bell",
      productunit: "",
    },
    {
      productname: "Pepper - Yellow Bell",
      productunit: "",
    },
    {
      productname: "Rosemary Leaf",
      productunit: "",
    },
    {
      productname: "Fresh Spinach ",
      productunit: "",
    },
    {
      productname: "Moi Moi Leaf",
      productunit: "",
    },
    {
      productname: "Bitter Leaf",
      productunit: "",
    },
    {
      productname: "Ewedu Leaf",
      productunit: "",
    },
    {
      productname: "Curry Leaf",
      productunit: "",
    },
    {
      productname: "Okazi Leaf",
      productunit: "",
    },
    {
      productname: "Oha Leaf",
      productunit: "",
    },
    {
      productname: "Scent Leaf ",
      productunit: "",
    },
    {
      productname: "Ugu Leaf ",
      productunit: "",
    },
    {
      productname: "Water Leaf ",
      productunit: "",
    },
    {
      productname: "Parsley leaf ",
      productunit: "",
    },
    {
      productname: "Basil Leaf",
      productunit: "",
    },
    {
      productname: "Hibiscus Leaf (Dry)",
      productunit: "",
    },
    {
      productname: "Uziza",
      productunit: "",
    },
    {
      productname: "Kale",
      productunit: "",
    },
    {
      productname: "Broccoli",
      productunit: "",
    },
    {
      productname: "Carrot ",
      productunit: "",
    },
    {
      productname: "Eggplant",
      productunit: "",
    },
    {
      productname: "Cabbage (Green)",
      productunit: "",
    },
    {
      productname: "Cabbage (Purple)",
      productunit: "",
    },
    {
      productname: "Lettuce ",
      productunit: "",
    },
    {
      productname: "Cucumber",
      productunit: "",
    },
    {
      productname: "Garden Egg",
      productunit: "",
    },
    {
      productname: "Fresh Sweet Corn ",
      productunit: "",
    },
    {
      productname: "Egusi - Hand Peeled",
      productunit: "",
    },
    {
      productname: "Egusi - Mahine Peeled ",
      productunit: "",
    },
    {
      productname: "Plantain - Unripe (Big)",
      productunit: "",
    },
    {
      productname: "Plantain - Unripe (Medium)",
      productunit: "",
    },
    {
      productname: "Plantain - Ripe (Big)",
      productunit: "",
    },
    {
      productname: "Plantain - Ripe (Medium)",
      productunit: "",
    },
    {
      productname: "Dried Plantain ",
      productunit: "",
    },
  ],
  Oil_and_Spices: [
    {
      productname: "Palm Oil - Local",
      productunit: "",
    },
    {
      productname: "Tumeric",
      productunit: "",
    },
    {
      productname: "Ginger",
      productunit: "",
    },
    {
      productname: "Palm Oil - Premium ",
      productunit: "",
    },
    {
      productname: "Palm Kernel Oil",
      productunit: "",
    },
    {
      productname: "Kings vegetable oil",
      productunit: "",
    },
    {
      productname: "Pepper Soup Spice",
      productunit: "",
    },
    {
      productname: "Banga Oil ",
      productunit: "",
    },
    {
      productname: "Moringa Seed",
      productunit: "",
    },
    {
      productname: "Pure Ground Ginger (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Black Pepper",
      productunit: "",
    },
    {
      productname: "Nutmeg",
      productunit: "",
    },
    {
      productname: "Rosemary Leaves (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Thyme (Dry)",
      productunit: "",
    },
    {
      productname: "Curry Powder (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Paprika (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Pure Ground White Pepper (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Basil Leaves (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Jollof Rice Spice - Spice Supreme",
      productunit: "",
    },
    {
      productname: "Fish Seasoning (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Fried Rice Spice (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Chicken Seasoning (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Banga Spice",
      productunit: "",
    },
    {
      productname: "Garlic (Spice Supreme)",
      productunit: "",
    },
    {
      productname: "Powdered Maggi Star Chicken Flavour",
      productunit: "",
    },
    {
      productname: "Suya Pepper",
      productunit: "",
    },
    {
      productname: "Uziza Seed",
      productunit: "",
    },
    {
      productname: "Fennel Seed",
      productunit: "",
    },
    {
      productname: "Cumin Seed",
      productunit: "",
    },
    {
      productname: "UAC Grand Pure Soya Oil",
      productunit: "",
    },
    {
      productname: "Black Seed",
      productunit: "",
    },
    {
      productname: "Star Anise",
      productunit: "",
    },
    {
      productname: "Oregano",
      productunit: "",
    },
    {
      productname: "Fenugreek Seed",
      productunit: "",
    },
  ],
  Fruits: [
    {
      productname: "Dates",
      productunit: "",
    },
    {
      productname: "Pineapple - Cotonou (Big)",
      productunit: "",
    },
    {
      productname: "Pineapple - Cotonou (Medium)",
      productunit: "",
    },
    {
      productname: "Pineapple - Cotonou (Small)",
      productunit: "",
    },
    {
      productname: "Tiger Nuts",
      productunit: "",
    },
    {
      productname: "Groundnut - Big Seed",
      productunit: "",
    },
    {
      productname: "Watermelon (Medium)",
      productunit: "",
    },
    {
      productname: "Watermelon (Small)",
      productunit: "",
    },
    {
      productname: "Watermelon (Big)",
      productunit: "",
    },
    {
      productname: "Avocado (Small)",
      productunit: "",
    },
    {
      productname: "Avocado (Medium)",
      productunit: "",
    },
    {
      productname: "Avocado (Big)",
      productunit: "",
    },
    {
      productname: "Banana - Native or Yoruba",
      productunit: "",
    },
    {
      productname: "Banana (Small)",
      productunit: "",
    },
    {
      productname: "Pawpaw (Medium)",
      productunit: "",
    },
    {
      productname: "Pawpaw (Small)",
      productunit: "",
    },
    {
      productname: "Pawpaw (Big)",
      productunit: "",
    },
    {
      productname: "Coconut",
      productunit: "",
    },
    {
      productname: "Mango",
      productunit: "",
    },
    {
      productname: "Apple",
      productunit: "",
    },
    {
      productname: "Apple Red",
      productunit: "",
    },
    {
      productname: "Sweet Melon",
      productunit: "",
    },
    {
      productname: "Strawberry",
      productunit: "",
    },
    {
      productname: "Lemon",
      productunit: "",
    },
    {
      productname: "Blueberries",
      productunit: "",
    },
    {
      productname: "Grapes",
      productunit: "",
    },
    {
      productname: "Tangerine",
      productunit: "",
    },
    {
      productname: "African Pear",
      productunit: "",
    },
    {
      productname: "English Pear",
      productunit: "",
    },
    {
      productname: "Orange",
      productunit: "",
    },
    {
      productname: "Tangelo (Small)",
      productunit: "",
    },
    {
      productname: "Tangelo (Big)",
      productunit: "",
    },
  ],
  Meat_and_Seafood: [
    {
      productname: "Chicken - Broiler (Whole)",
      productunit: "",
    },
    {
      productname: "Chicken - Old Layer (Whole)",
      productunit: "",
    },
    {
      productname: "Snails Dry (Big)",
      productunit: "",
    },
    {
      productname: "Snails Dry (Jumbo)",
      productunit: "",
    },
    {
      productname: "Snails Dry (Small)",
      productunit: "",
    },
    {
      productname: "Chicken - Broiler",
      productunit: "",
    },
    {
      productname: "Cow Meat or Beef - With Bone",
      productunit: "",
    },
    {
      productname: "Cow Meat or Beef - Boneless",
      productunit: "",
    },
    {
      productname: "Ponmo",
      productunit: "",
    },
    {
      productname: "Ram Meat - Frozen",
      productunit: "",
    },
    {
      productname: "Ram Meat - Big (Full)",
      productunit: "",
    },
    {
      productname: "Ram Meat - Big (Half)",
      productunit: "",
    },
    {
      productname: "Ram Meat - Big (Quarter)",
      productunit: "",
    },
    {
      productname: "Ram Meat - Medium (Full)",
      productunit: "",
    },
    {
      productname: "Ram Meat - Medium (Half)",
      productunit: "",
    },
    {
      productname: "Ram Meat - Medium (Quarter)",
      productunit: "",
    },
    {
      productname: "Ram Meat - Small (Full)",
      productunit: "",
    },
    {
      productname: "Ram Meat - Small (Half)",
      productunit: "",
    },
    {
      productname: "Ram Meat - Small (Quarter)",
      productunit: "",
    },
    {
      productname: "Ram - Live (Small)",
      productunit: "",
    },
    {
      productname: "Ram - Live (Medium)",
      productunit: "",
    },
    {
      productname: "Ram - Live (Extra Large)",
      productunit: "",
    },
    {
      productname: "Goat Meat - With Bone",
      productunit: "",
    },
    {
      productname: "Goat Meat - Female (Full)",
      productunit: "",
    },
    {
      productname: "Goat Meat - Male (Full)",
      productunit: "",
    },
    {
      productname: "Goat Meat - Female (Half)",
      productunit: "",
    },
    {
      productname: "Goat Meat - Female (Quarter)",
      productunit: "",
    },
    {
      productname: "Prawns - Dry (Big)",
      productunit: "",
    },
    {
      productname: "Fish - Tilapia",
      productunit: "",
    },
    {
      productname: "Thailand Fish (Small)",
      productunit: "",
    },
    {
      productname: "Thailand Fish (Medium)",
      productunit: "",
    },
    {
      productname: "Thailand Fish (Big)",
      productunit: "",
    },
    {
      productname: "Asa Fish or Eja Osan (Big)",
      productunit: "",
    },
    {
      productname: "Asa Fish or Eja Osan (Medium)",
      productunit: "",
    },
    {
      productname: "Calamari - Cleaned",
      productunit: "",
    },
    {
      productname: "Prawns - Unpeeled (Jumbo)",
      productunit: "",
    },
    {
      productname: "Frozen Turkey",
      productunit: "",
    },
    {
      productname: "Chicken - Nigerian",
      productunit: "",
    },
    {
      productname: "Chicken - Hard Lap Orobo",
      productunit: "",
    },
    {
      productname: "Chicken - Gizzard",
      productunit: "",
    },
    {
      productname: "Chi Smoked Whole Chicken",
      productunit: "",
    },
    {
      productname: "Smoked Hake Fish or Eja Egun (Big)",
      productunit: "",
    },
    {
      productname: "Smoked Hake Fish or Eja Egun (Medium)",
      productunit: "",
    },
    {
      productname: "Smoked Hake Fish or Eja Egun (Small)",
      productunit: "",
    },
    {
      productname: "Snails (Small)",
      productunit: "",
    },
    {
      productname: "Snails (Medium)",
      productunit: "",
    },
    {
      productname: "Snails (Jumbo)",
      productunit: "",
    },
    {
      productname: "Snails (Big)",
      productunit: "",
    },
    {
      productname: "Smoked Catfish - Agric (Medium)",
      productunit: "",
    },
    {
      productname: "Smoked Catfish - Agric (Small)",
      productunit: "",
    },
    {
      productname: "Smoked Catfish - (Small)",
      productunit: "",
    },
    {
      productname: "Smoked Catfish - (Medium)",
      productunit: "",
    },
    {
      productname: "Smoked Asa - (Small)",
      productunit: "",
    },
    {
      productname: "Smoked Asa - (Medium)",
      productunit: "",
    },
    {
      productname: "Mangala Fish - Cut Cut",
      productunit: "",
    },
    {
      productname: "River Catfish Mangala - Whole",
      productunit: "",
    },
    {
      productname: "Fish - Titus",
      productunit: "",
    },
    {
      productname: "Oxtail",
      productunit: "",
    },
    {
      productname: "Shaki or Beef Tripe",
      productunit: "",
    },
    {
      productname: "Frozen Turkey",
      productunit: "",
    },
    {
      productname: "Chicken - Soft Lap Orobo",
      productunit: "",
    },
    {
      productname: "Periwinkle - Without Shell",
      productunit: "",
    },
    {
      productname: "Fish - Frozen Croaker",
      productunit: "",
    },
    {
      productname: "Bonga Fish",
      productunit: "",
    },
    {
      productname: "Crayfish",
      productunit: "",
    },
    {
      productname: "Stockfish Panla - Body",
      productunit: "",
    },
    {
      productname: "Periwinkle - With Shell",
      productunit: "",
    },
    {
      productname: "Chicken - Wings",
      productunit: "",
    },
    {
      productname: "Guinea Fowl",
      productunit: "",
    },
  ],
  Baking: [
    {
      productname: "Amel Susan Bakery Cocoa Powder",
      productunit: "",
    },
    {
      productname: "Eggs",
      productunit: "",
    },
    {
      productname: "Baker's Choice Browning",
      productunit: "",
    },
    {
      productname: "Amel Susan Baking Soda",
      productunit: "",
    },
    {
      productname: "Marvico Nutmeg Powder",
      productunit: "",
    },
    {
      productname: "Foster Clark's Strawberry Essence",
      productunit: "",
    },
    {
      productname: "Dangote Sugar (50kg)",
      productunit: "",
    },
    {
      productname: "Napa Valley Baking Powder",
      productunit: "",
    },
    {
      productname: "Golden Penny Prime Flour",
      productunit: "",
    },
    {
      productname: "STK Royal Instant Dry Yeast",
      productunit: "",
    },
    {
      productname: "Simas Cooking Margarine",
      productunit: "",
    },
    {
      productname: "King's Cooking Margarine",
      productunit: "",
    },
    {
      productname: "Napa Valley Cooking Margarine",
      productunit: "",
    },
    {
      productname: "Victory Baking Powder",
      productunit: "",
    },
    {
      productname: "Foster Clark's Vanilla Essence",
      productunit: "",
    },
    {
      productname: "Dangote Sugar",
      productunit: "",
    },
    {
      productname: "Pelik & Kano Whipped Cream Powder",
      productunit: "",
    },
    {
      productname: "Amel Susan Icing Sugar",
      productunit: "",
    },
    {
      productname: "Honey",
      productunit: "",
    },
  ],
};
