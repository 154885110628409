import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
    collection,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import config from 'constants/config';
import { toast } from 'react-toastify';
import { store } from 'redux/store';
import { formatDateValues } from './formatDateValues';
import { ITEMS_PER_PAGE } from 'constants/global';

const app = initializeApp(config.FB);
getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export const uploadFile = async (filePathAndName, file, setReturnType, setProgressFunc, setUploadTaskInstance) => {
    const storageRef = ref(storage, filePathAndName);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
        'state_changed',
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgressFunc && setProgressFunc(Math.floor(progress));
        },
        (error) => {
            error.code !== 'storage/canceled' && toast.error(error.message + '. Please reupload ' + file.name);
            setReturnType('error');
        },
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => setReturnType(downloadURL));
        }
    );

    setUploadTaskInstance && setUploadTaskInstance(uploadTask);
};

export const fetchCollectionToRedux = async (
    collectionPath,
    setCollection,
    setLoading,
    limitCount = ITEMS_PER_PAGE
) => {
    try {
        setLoading(true);
        const q = query(collection(db, collectionPath), orderBy('createdAt', 'desc'), limit(limitCount));
        const collectionSnapshot = await getDocs(q);
        const dataArr = [];
        collectionSnapshot.forEach((doc) => {
            dataArr.push({
                ...doc.data(),
                ...formatDateValues(doc.data()),
            });
        });

        collectionSnapshot.size && store.dispatch(setCollection(dataArr));
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
};

export const modifyAuthError = (firebaseString) => {
    const removedChars = firebaseString.replace(/[/-]/g, ' ');
    const updatedString = removedChars
        .split(' ')
        .filter((word, idx) => idx)
        .join(' ');
    return 'Request failed. ' + updatedString.charAt(0).toUpperCase() + updatedString.slice(1) + '!';
};

export const fetchCollectionCount = async (collectionPath, setCount, showError = false) => {
    try {
        const collectionSnapshot = await getCountFromServer(collection(db, collectionPath));
        const count = collectionSnapshot.data().count;
        count && setCount(count);
    } catch (error) {
        showError && toast.error(`Error fetching total number of ${collectionPath}. Please reload the page.`);
    }
};

export const fetchCollectionCountByQuery = async (collectionQuery, setCount, setLoading, showError = false) => {
    try {
        setLoading(true);
        const collectionSnapshot = await getCountFromServer(collectionQuery);
        const count = collectionSnapshot.data().count;
        setCount(count);
    } catch (error) {
        if (showError) {
            toast.error(error?.message);
        }
    } finally {
        setLoading(false);
    }
};

export const fetchCollectionByQuery = async (collectionQuery, setLoading, setCollection) => {
    setLoading(true);
    const dataArr = [];
    try {
        const dataArrRef = await getDocs(collectionQuery);
        dataArrRef.forEach((doc) =>
            dataArr.push({
                ...doc.data(),
                ...formatDateValues(doc.data()),
            })
        );
        store.dispatch(setCollection(dataArr));
    } catch (error) {
        toast.error(error?.message);
    } finally {
        setLoading(false);
    }
};

export const fetchCollectionByQueryLocal = async (collectionQuery, setLoading, setData, showError = false) => {
    setLoading(true);
    const dataArr = [];
    try {
        const dataArrRef = await getDocs(collectionQuery);
        dataArrRef.forEach((doc) =>
            dataArr.push({
                ...doc.data(),
                ...formatDateValues(doc.data()),
            })
        );
        dataArrRef.size && setData(dataArr);
    } catch (error) {
        if (showError) {
            toast.error(typeof showError === 'string' ? showError : error?.message);
        }
        // console.log(error, 'fetch collection error');
    } finally {
        setLoading(false);
    }
};

export const fetchCollectionWithPagination = async (
    collectionQuery,
    setLoading,
    setData,
    setLastDocRef,
    showError = true
) => {
    setLoading(true);
    const dataArr = [];
    try {
        const dataArrRef = await getDocs(collectionQuery);
        dataArrRef.forEach((doc) =>
            dataArr.push({
                ...doc.data(),
                ...formatDateValues(doc.data()),
            })
        );
        setLastDocRef(dataArrRef.docs[dataArrRef.docs.length - 1]);
        dataArrRef.size && setData(dataArr);
    } catch (error) {
        if (showError) {
            error?.message !== 'nextPageData is not iterable' &&
                toast.error(typeof showError === 'string' ? showError : error?.message);
        }
    } finally {
        setLoading(false);
    }
};

export const fetchDocument = async (documentPath, documentId, setLoading, setData, showError = false) => {
    setLoading(true);

    try {
        const docRef = doc(db, documentPath, documentId);
        const docSnap = await getDoc(docRef);

        docSnap.exists() &&
            setData({
                ...docSnap.data(),
                ...formatDateValues(docSnap.data()),
            });
    } catch (error) {
        if (showError) {
            toast.error(typeof showError === 'string' ? showError : error?.message);
        }
    } finally {
        setLoading(false);
    }
};
