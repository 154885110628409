import { DASHBOARD_TYPES } from 'constants/global';

export const saveToStorage = (name, data) => {
    localStorage.setItem(name, JSON.stringify(data));
};

export const getFromStorage = (name) => {
    return JSON.parse(localStorage.getItem(name));
};

export const removeFromStorage = (name) => {
    localStorage.removeItem(name);
};

export const setUser = (user) => {
    user &&
        saveToStorage(
            'ams-dashboard-type',
            user?.role === 'super admin' || user?.role === 'accountant'
                ? getFromStorage('ams-dashboard-type') ?? DASHBOARD_TYPES.WHOLESALE
                : user?.role === DASHBOARD_TYPES.LOGISTICS
                  ? user.role
                  : DASHBOARD_TYPES.WHOLESALE
        );
    saveToStorage('user', user);
    window.dispatchEvent(new Event('storage'));
};

export const getUser = () => getFromStorage('user');
